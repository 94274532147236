import React from "react";
import SideBarItem from "./SidebarItem";

import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { getImageUrl } from "../../../utils";
import { envs, logos } from "../../../utils/constants";
import "./sidebar.css";

interface Items {
	id: string;
	title: string;
	icon: string;
	url: string;
}

const SideBar: React.FC = () => {
	const permissions = useSelector(
		(state: RootState) => state.authPermission.permissions
	);
	const showSideBar = useSelector(
		(state: RootState) => state.showSideBar.value
	);

	const shop = useSelector((state: RootState) => state.auth.shop);

	const items: Items[] = [
		{
			id: "1",
			title: "home",
			icon: "one",
			url: "",
		},
		{
			id: "23",
			title: "Themes",
			icon: "two",
			url: "themes",
		},
		{
			id: "2",
			title: "Settings",
			icon: "two",
			url: "widgets",
		},
		{
			id: "21",
			title: "Headers",
			icon: "two",
			url: "headers",
		},
		{
			id: "22",
			title: "Footers",
			icon: "two",
			url: "footers",
		},
		{
			id: "5",
			title: "menus",
			icon: "four",
			url: "menus",
		},
		{
			id: "3",
			title: "page layout",
			icon: "three",
			url: "pages",
		},
		{
			id: "31",
			title: "Landing Pages",
			icon: "three",
			url: "landing-pages",
		},
		{
			id: "32",
			title: "Product Settings",
			icon: "two",
			url: "product-settings",
		},
		{
			id: "4",
			title: "media",
			icon: "three",
			url: "media",
		},
	];
	return (
		<div className="sidebar__container">
			<div className="logo__wrapper">
				<div className="logo">
					<a href={envs.adminUrl}>
						{showSideBar ? (
							<img
								className="w-full h-24 aspect-auto p-6"
								src={
									shop?.logo
										? getImageUrl(shop?.logo)
										: logos.fullLogoBlack
								}
								alt="logo"
							/>
						) : (
							<img
								className="w-full h-24 aspect-auto p-3"
								src={
									shop?.favicon
										? getImageUrl(shop?.favicon)
										: logos.iconOng
								}
								alt="logo"
							/>
						)}
					</a>
				</div>
			</div>
			<div className="sidebar__row">
				<div className="sidebar__item--wrapper">
					{items.map((item) => {
						return (
							<SideBarItem
								title={item.title}
								icon={item.icon}
								url={item.url}
								key={item.id}
							/>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default SideBar;

import { useState } from "react";
import { CustomFormProps } from ".";
import SettingFormItems, {
	SettingItem,
	SettingItems,
} from "../SettingFormItems";
import classes from "./CWForm.module.css";

export const objectKeys = Object.keys as <T>(o: T) => (keyof T)[];

type SettingsNames =
	| "bgColor"
	| "primaryColor"
	| "secondaryColor"
	| "headerColor"
	| "trackOrderBtn"
	| "fullHeader"
	| "fbPageId";

type ProductSettingsNames =
	| "hideReview"
	| "hideBrand"
	| "cardWidth"
	| "cardHeight";

type CheckoutPageSettingsNames = "showFloatingCart" | "confirmOrderBtn";

const themeSettings: SettingItem<SettingsNames>[] = [
	{
		name: "Background Color",
		type: "color",
		key: "bgColor",
	},
	{
		name: "Primary Color",
		type: "color",
		key: "primaryColor",
	},
	{
		name: "Secondary Color",
		type: "color",
		key: "secondaryColor",
	},
];

const productSettings: SettingItem<ProductSettingsNames>[] = [
	{
		name: "Hide Review",
		type: "checkbox",
		key: "hideReview",
	},
	{
		name: "Hide Brand",
		type: "checkbox",
		key: "hideBrand",
	},
	{
		name: "Card Width",
		type: "number",
		key: "cardWidth",
	},
	{
		name: "Card Height",
		type: "number",
		key: "cardHeight",
	},
];

const checkoutPageSettings: SettingItem<CheckoutPageSettingsNames>[] = [
	{
		name: "Show Floating Cart",
		type: "checkbox",
		key: "showFloatingCart",
	},
	{
		name: "Confirm Order Button Type",
		type: "select",
		key: "confirmOrderBtn",
		options: [
			{
				name: "Fixed Bottom",
				value: "fixedBottom",
			},
			{
				name: "Default",
				value: "default",
			},
		],
	},
];

const headerSettings: SettingItem<SettingsNames>[] = [
	// {
	// 	name: "Header Color",
	// 	type: "color",
	// 	key: "headerColor",
	// },
	// {
	// 	name: "Track Order Button",
	// 	type: "checkbox",
	// 	key: "trackOrderBtn",
	// },
	// {
	// 	name: "is Full Header",
	// 	type: "checkbox",
	// 	key: "fullHeader",
	// },
	// {
	// 	name: "Facebook Page Id",
	// 	type: "text",
	// 	key: "fbPageId",
	// },
];

const ThemeSettingsForm = ({
	formItems,
	customForm = false,
	name = "",
	onSubmit = () => {},
	title,
	className,
	style,
}: CustomFormProps) => {
	const [settings, setSettings] = useState<
		SettingItems<SettingsNames | CheckoutPageSettingsNames>
	>(formItems as any);

	const [cardSettings, setCardSettings] = useState<
		SettingItems<ProductSettingsNames>
	>((formItems as any)?.cardSettings || {});

	const classNames = [classes.CWForm, className];

	const handleSubmit = () => {
		const data: any = {
			...settings,
			cardSettings,
		};

		onSubmit(data as any);
	};

	return (
		<div className={classNames.join(" ")} style={style}>
			<form
				className="mainSliderForm__container"
				onSubmit={(e) => {
					e.preventDefault();
				}}
			>
				<h1 className="title capitalize">{title}</h1>

				<SettingFormItems
					title="Theme Settings"
					settings={settings}
					settingItems={themeSettings}
					onChange={(key, value) => {
						setSettings((prev) => ({
							...prev,
							[key]: value,
						}));
					}}
				/>

				<SettingFormItems
					title="Product Card Settings"
					settings={cardSettings}
					settingItems={productSettings}
					onChange={(key, value) => {
						setCardSettings((prev) => ({
							...prev,
							[key]: value,
						}));
					}}
				/>

				<SettingFormItems
					title="Checkout Page Settings"
					settings={settings}
					settingItems={checkoutPageSettings}
					onChange={(key, value) => {
						setSettings((prev) => ({
							...prev,
							[key]: value,
						}));
					}}
				/>

				{/* <SettingFormItems
					title="Header Settings"
					settings={settings}
					settingItems={headerSettings}
					onChange={(key, value) => {
						setSettings((prev) => ({
							...prev,
							[key]: value,
						}));
					}}
				/> */}

				<div>
					<button
						onClick={(e) => {
							e.preventDefault();
							handleSubmit();
						}}
						className="mainSliderForm__btn"
					>
						save
					</button>
				</div>
			</form>
		</div>
	);
};

export default ThemeSettingsForm;

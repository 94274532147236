import {
	ArrowLeft,
	CloseCircleOutline,
	Dropbox,
	Folder,
	FolderPlus,
} from "mdi-material-ui";
import {
	ChangeEvent,
	ElementType,
	UIEvent,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";

import { CircularProgress, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";
import {
	createFolder,
	fetchImagesModule,
	uploadImageForm,
} from "../modules/mediaManager";
import { getImageUrl, toastMe } from "../utils";
import CloseIcon from "./icons/CloseIcon";

const ButtonStyled = styled(Button)<
	ButtonProps & { component?: ElementType; htmlFor?: string }
>(({ theme }) => ({
	[theme.breakpoints.down("sm")]: {
		width: "100%",
		textAlign: "center",
	},
}));

export interface PopUpImage {
	id: number;
	url: string;
	path?: string;
}

interface ImageType {
	id: number;
	url: string;
	path?: string;
	mimetype?: string;
	name?: string;
	parentId?: number;
}

const PER_PAGE = 12;
const ImagePopUpV3 = ({
	onUpdate,
	onClose,
	single = false,
	v2,
}: {
	onUpdate: (images: ImageType[]) => void;
	onClose: () => void;
	single?: boolean;
	v2?: boolean;
}) => {
	const dispatch = useDispatch();
	const [chooseItemId, setChooseItemId] = useState(0);
	const [choosenImages, setChoosenImages] = useState<ImageType[]>([]);

	const [mediaResponse, setMediaResponse] = useState<any>();

	const [loading, setLoading] = useState<boolean>(false);
	const [parentIds, setParentIds] = useState<number[]>([]);
	const parentId = useMemo(
		() => (parentIds.length > 0 ? parentIds[parentIds.length - 1] : null),
		[parentIds]
	);

	const apiCalling = useRef(false);

	const [onCreateFolder, setOnCreateFolder] = useState<boolean>(false);
	const [folderName, setFolderName] = useState<string>("");

	const [pagePagination, setPagePagination] = useState<{
		total: number;
		lastPage: number;
		currentPage: number;
	}>({
		total: 0,
		lastPage: 2,
		currentPage: 1,
	});

	const images: ImageType[] = mediaResponse?.data || [];

	const getMediaListCallback = useCallback(
		({ currentPage }: { currentPage: number }) => {
			if (apiCalling.current) return Promise.resolve();

			apiCalling.current = true;
			setLoading(true);

			return fetchImagesModule(
				{
					currentPage,
					lastPage: 2,
					total: 0,
				},
				parentId || undefined
			)
				.then((response) => {
					setMediaResponse((prev: any) => {
						if (!prev) return response;

						return {
							...prev,
							data: [...prev.data, ...response.data],
						};
					});

					setPagePagination((prev) => ({
						...prev,
						total: response.totalResult,
						lastPage: response.totalPage,
						currentPage: response.currentPage,
					}));
				})
				.finally(() => {
					apiCalling.current = false;
					setLoading(false);
				});
		},
		[parentId]
	);

	useEffect(() => {
		getMediaListCallback({ currentPage: 1 });
	}, [getMediaListCallback]);

	const handlePageScroll = (e: UIEvent<HTMLDivElement>) => {
		const target = e.target as HTMLDivElement;

		if (
			!apiCalling.current &&
			pagePagination.currentPage < pagePagination.lastPage &&
			target.scrollTop + target.clientHeight + 120 >= target.scrollHeight
		) {
			getMediaListCallback({
				currentPage: pagePagination.currentPage + 1,
			});

			const updatedPagination = {
				...pagePagination,
				currentPage: pagePagination.currentPage + 1,
			};

			setPagePagination(updatedPagination);
		}
	};

	const uploadImages = async (files: File[]) => {
		if (files && files.length !== 0) {
			const images: File[] = Array.from(files);

			setLoading(true);

			for await (const image of images) {
				const formData = new FormData();

				formData.append("file", image);
				formData.append("name", image.name);
				if (parentId)
					formData.append(
						"parentId",
						(parentId || null) as any as string
					);

				try {
					const res: any = await uploadImageForm(formData);
					const data = res.data;

					if (data) {
						setMediaResponse((prev: any) => {
							if (!prev) return prev;

							return {
								...prev,
								data: [data, ...prev.data],
							};
						});
					}
				} catch (err: any) {
					console.log(err);
					toastMe(
						err?.response?.data?.message ||
							"Failed to upload image",
						"error"
					);
				}
			}

			setLoading(false);
		}
	};

	const onChange = (file: ChangeEvent) => {
		const { files } = file.target as HTMLInputElement;

		if (files) {
			uploadImages(Array.from(files));
			(file.target as HTMLInputElement)!.files = null;
			(file.target as HTMLInputElement)!.value = "";
		}
	};

	const onDrop = (acceptedFiles: File[]) => {
		if (acceptedFiles && acceptedFiles.length > 0) {
			uploadImages(acceptedFiles);
		}
	};

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		accept: {
			"image/*": [],
		},
	});

	const handleChooseImage = (image: any) => {
		setChooseItemId(image?.id);

		let _choosenImages = [...choosenImages];

		if (single) {
			_choosenImages = [];
		}

		if (_choosenImages.find((item) => item.id === image.id)) {
			_choosenImages = _choosenImages.filter(
				(item) => item.id !== image.id
			);
		} else {
			_choosenImages.push(image);
		}

		setChoosenImages(Array.from(new Set(_choosenImages)));
	};

	const handleInsertImages = () => {
		onUpdate(choosenImages);
	};

	const deleteChoosenImage = (id: number) => {
		const filter = choosenImages.filter((item) => item.id !== id);
		setChoosenImages(filter);
	};

	const handleCreateFolder = async () => {
		setLoading(true);
		setOnCreateFolder(false);

		try {
			const folder = await createFolder(folderName, parentId!);
			setFolderName("");
			if (folder) {
				setMediaResponse((prev: any) => {
					if (!prev) return prev;

					return {
						...prev,
						data: [folder, ...prev.data],
					};
				});
			}
		} catch (error) {
			console.log(error);
			toastMe(error, "error", "Can't create folder");
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className="image__popup--container">
			<div className="image__popup--row">
				<div className="image__popup--col" {...getRootProps()}>
					{isDragActive && (
						<div className="absolute w-full h-full top-0 left-0 z-50 bg-white/50 flex items-center justify-center text-[5rem]">
							<div className=" flex flex-col items-center">
								<Dropbox
									className="text-black text-4xl animate-ping"
									fontSize="inherit"
								/>
								<p className="text-black text-lg font-semibold">
									Drop your files here
								</p>
							</div>

							<input {...getInputProps()} />
						</div>
					)}

					{parentId && (
						<div className="absolute left-2 top-2 z-50">
							<Button
								variant="outlined"
								size="small"
								onClick={() => {
									setParentIds((prev) =>
										prev.slice(0, prev.length - 1)
									);
									setPagePagination((prev) => ({
										...prev,
										currentPage: 1,
										lastPage: 2,
									}));

									setMediaResponse(undefined);
								}}
								sx={{ marginRight: 1 }}>
								<ArrowLeft /> Back
							</Button>

							<Typography
								variant="subtitle2"
								textTransform="uppercase">
								{parentId
									? mediaResponse?.data.find(
											(item: ImageType) =>
												item.id === parentId
									  )?.name
									: "All Media"}
							</Typography>
						</div>
					)}

					<div
						className="image__popup--wrapper"
						onScroll={handlePageScroll}>
						<div className="p-6">
							<div className="grid grid-cols-4 gap-6 pb-32">
								{images?.map((image, index) => {
									if (image.mimetype === "folder")
										return (
											<button
												key={index}
												className={`w-full h-full p-2 shadow-lg relative bg-white cursor-pointer`}
												onClick={() => {
													setParentIds((prev) => [
														...prev,
														image.id,
													]);
													setMediaResponse(undefined);
													setPagePagination(
														(prev) => ({
															...prev,
															currentPage: 1,
															lastPage: 2,
														})
													);
												}}>
												{/* folder name */}
												<div className="absolute bottom-0 left-0 w-full h-10 bg-white bg-opacity-80 flex items-center justify-center">
													<Typography
														variant="subtitle2"
														className="text-center">
														{image.name}
													</Typography>
												</div>

												<div className="w-full h-40 aspect-auto object-contain object-center bg-gray-100 cursor-pointer flex items-center justify-center">
													<Folder
														fontSize="large"
														className="scale-[2]"
													/>
												</div>
											</button>
										);

									return (
										<div
											key={index}
											className={`w-full h-full p-2 shadow-lg cursor-pointer ${
												// chooseItemId === image?.id ? 'bg-blue-300' : 'bg-white'
												choosenImages.find(
													(i) => i.id === image?.id
												)
													? "bg-blue-300"
													: "bg-white"
											}`}
											onClick={() =>
												handleChooseImage(image)
											}>
											<img
												className="w-full h-40 aspect-auto object-contain object-center bg-gray-100"
												src={getImageUrl(
													image.path || ""
												)}
												alt={image.name}
											/>
										</div>
									);
								})}

								{loading && (
									<div className="col-span-full flex items-center justify-center">
										<CircularProgress />
									</div>
								)}

								{images?.length === 0 && !loading && (
									<div className="col-span-full flex items-center justify-center">
										<Typography variant="subtitle2">
											No Data
										</Typography>
									</div>
								)}
							</div>
						</div>
					</div>

					<div
						className="image__popup--close-btn"
						onClick={() => onClose()}>
						<CloseCircleOutline />
					</div>
					<div className="absolute w-full h-32 bg-white bottom-0 right-0 left-0 grid grid-cols-3 gap-2 p-2 shadow">
						<div className="flex flex-col px-2 gap-2 items-center justify-center bg-gray-200">
							<Box>
								<ButtonStyled
									component="label"
									variant="contained"
									htmlFor="account-settings-upload-image"
									disabled={loading}>
									Upload New Photo
									{loading && (
										<CircularProgress
											size={18}
											sx={{ marginLeft: 4 }}
										/>
									)}
									<input
										hidden
										type="file"
										onChange={onChange}
										accept="image/png, image/jpeg"
										id="account-settings-upload-image"
									/>
								</ButtonStyled>
							</Box>

							<ButtonStyled
								component="label"
								variant="outlined"
								disabled={loading}
								startIcon={<FolderPlus />}
								onClick={() => setOnCreateFolder(true)}>
								Create Folder
							</ButtonStyled>
						</div>

						<div className="bg-gray-200 col-span-2 grid grid-cols-3">
							<div className="col-span-2 flex items-center gap-2 mx-2 pr-1 overflow-x-auto w-full">
								{choosenImages.map((image, index) => {
									return (
										<div key={index} className="relative">
											<img
												key={image?.id}
												className="w-16 h-16 bg-white rounded-md shadow-md mr-4 last:mr-2 aspect-auto object-contain object-center"
												src={getImageUrl(
													image.path || ""
												)}
												alt={image.name}
											/>
											<div
												className="image__popup--close-btn-two"
												onClick={() =>
													deleteChoosenImage(
														image?.id
													)
												}>
												<CloseCircleOutline />
											</div>
										</div>
									);
								})}
							</div>
							<div className="flex items-center pr-4 pl-2">
								<button
									className="capitalize bg-blue-500 px-8 py-3 text-white tracking-wider rounded-md shadow-lg w-full"
									onClick={handleInsertImages}>
									insert
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div
				className={`fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-50 ${
					onCreateFolder ? "block" : "hidden"
				}`}>
				<div
					className="absolute w-full h-full left-0 top-0 -z-10"
					onClick={() => {
						setOnCreateFolder(false);
						setFolderName("");
					}}></div>
				<div className="bg-white rounded-md shadow-lg p-6">
					<div className="flex items-center justify-between mb-4">
						<h1 className="text-xl font-bold">Create Folder</h1>
						<div
							className="cursor-pointer"
							onClick={() => {
								setOnCreateFolder(false);
								setFolderName("");
							}}>
							<CloseIcon />
						</div>
					</div>
					<div className="flex items-center justify-between gap-2">
						<input
							type="text"
							placeholder="Folder Name"
							className="w-full rounded-md border border-gray-300 px-4 py-2 focus:outline-none focus:border-blue-500"
							value={folderName}
							onChange={(e) => setFolderName(e.target.value)}
						/>
						<button
							className="bg-blue-500 text-white px-4 py-2 rounded-md"
							onClick={handleCreateFolder}>
							Create
						</button>
					</div>
				</div>
			</div>

			{/* {onCreateFolder && (
        <PopupManager onClose={() => setOnCreateFolder(false)}>
          <CustomForm
            items={[
              {
                fieldKey: 'name',
                label: 'Folder Name',
                type: 'text',
                required: true,
                span: true
              }
            ]}
            onSubmit={data => {
              setLoading(true)

              createFolder({
                name: data.name,
                parentId: parentId || null
              })
                .finally(() => {
                  setLoading(false)
                })
                .then((data: any) => {
                  setMediaResponse((prev: any) => {
                    if (!prev) return prev

                    return {
                      ...prev,
                      data: [data, ...prev.data]
                    }
                  })
                })

              setOnCreateFolder(false)
            }}
          />
        </PopupManager>
      )} */}
		</div>
	);
};

export default ImagePopUpV3;

import { useEffect, useState } from "react";
import SettingFormItems, {
	SettingItem,
	SettingItems,
} from "../../components/SettingFormItems";
import { findWidgetByName } from "../../utils";
import { updateWidgetItem } from "../../utils/requests";
import { showToastV2 } from "../WidgetBuilder";

type SettingsNames =
	| "hideBrand"
	| "hideReview"
	| "hideRelatedProducts"
	| "hideSimilarProducts";

type CardSettingsNames =
	| "hideBrand"
	| "hideReview"
	| "cardWidth"
	| "cardHeight"
	| "addToCart"
	| "buyNow"
	| "showDiscount"
	| "discountType";

const productSettings: SettingItem<SettingsNames>[] = [
	{
		name: "Hide Brand",
		key: "hideBrand",
		type: "checkbox",
	},
	{
		name: "Hide Review",
		key: "hideReview",
		type: "checkbox",
	},
	{
		name: "Hide Related Products",
		key: "hideRelatedProducts",
		type: "checkbox",
	},
	{
		name: "Hide Similar Products",
		key: "hideSimilarProducts",
		type: "checkbox",
	},
];

const cardSettingsItems: SettingItem<CardSettingsNames>[] = [
	{
		name: "Card Image Width",
		type: "number",
		key: "cardWidth",
	},
	{
		name: "Card Image Height",
		type: "number",
		key: "cardHeight",
	},
	{
		name: "Add To Cart Button",
		type: "checkbox",
		key: "addToCart",
	},
	{
		name: "Buy Now Button",
		type: "checkbox",
		key: "buyNow",
	},
	{
		name: "Show Discount",
		key: "showDiscount",
		type: "checkbox",
	},
	{
		name: "Discount Type",
		key: "discountType",
		type: "select",
		options: [
			{ name: "Percentage", value: "percentage" },
			{ name: "Amount", value: "amount" },
		],
	},
	{
		name: "Hide Brand",
		key: "hideBrand",
		type: "checkbox",
	},
	{
		name: "Hide Review",
		key: "hideReview",
		type: "checkbox",
	},
];

const ProductSettings = () => {
	const [settings, setSettings] = useState<SettingItems<SettingsNames>>({});
	const [cardSettings, setCardSettings] = useState<
		SettingItems<CardSettingsNames>
	>({});

	const [themeSettingsW, setThemeSettingsW] = useState<any | null>(null);

	useEffect(() => {
		findWidgetByName("Theme Settings").then((res) => {
			setThemeSettingsW(res);

			setSettings({
				hideBrand: res?.settings?.productSettings?.hideBrand || false,
				hideReview: res?.settings?.productSettings?.hideReview || false,
				hideRelatedProducts:
					res?.settings?.productSettings?.hideRelatedProducts ||
					false,
				hideSimilarProducts:
					res?.settings?.productSettings?.hideSimilarProducts ||
					false,
			});

			setCardSettings({
				hideBrand: res?.settings?.cardSettings?.hideBrand || false,
				hideReview: res?.settings?.cardSettings?.hideReview || false,
				cardWidth: res?.settings?.cardSettings?.cardWidth || 0,
				cardHeight: res?.settings?.cardSettings?.cardHeight || 0,
				addToCart: res?.settings?.cardSettings?.addToCart || false,
				buyNow: res?.settings?.cardSettings?.buyNow || false,
				showDiscount:
					res?.settings?.cardSettings?.showDiscount || false,
				discountType:
					res?.settings?.cardSettings?.discountType || "percentage",
			});
		});
	}, []);

	const handlePublish = () => {
		const productSettings = {
			...themeSettingsW.settings?.productSettings,
			...settings,
		};

		updateWidgetItem({
			...themeSettingsW,
			status: "published",
			active: true,
			settings: {
				...themeSettingsW.settings,
				productSettings,
				cardSettings,
			},
		})
			.then((res) => {
				showToastV2("Footer updated successfully");
			})
			.catch(() => {
				showToastV2("Footer update failed!", "error");
			});
	};

	return (
		<div className="p-4">
			<div className="sticky top-[60px] left-0 z-50 bg-white py-4 w-full shadow-md px-2 flex justify-between">
				<h1 className="text-2xl font-bold">Product Settings</h1>

				<div className="flex gap-4 items-center">
					{/* <button
						className="bg-gray-500 text-white px-4 py-2 rounded-md"
						onClick={handleToDraft}>
						Safe to draft
					</button> */}

					<button
						className="bg-green-500 text-white px-4 py-2 rounded-md"
						onClick={handlePublish}
					>
						Publish
					</button>
				</div>
			</div>

			<div className="grid lg:grid-cols-3 gap-4">
				<div className="mt-4 border border-gray-300 px-4 py-1">
					<p className="text-lg font-semibold">
						Product Page Settings :
					</p>
					<SettingFormItems
						settings={settings}
						settingItems={productSettings}
						onChange={(key, value) => {
							setSettings((prev) => ({
								...prev,
								[key]: value,
							}));
						}}
					/>
				</div>

				<div className="mt-4 border border-gray-300 px-4 py-1">
					<p className="text-lg font-semibold">Card Settings :</p>
					<SettingFormItems
						settings={cardSettings}
						settingItems={cardSettingsItems}
						onChange={(key, value) => {
							setCardSettings((prev) => ({
								...prev,
								[key]: value,
							}));
						}}
					/>
				</div>
			</div>
		</div>
	);
};

export default ProductSettings;
